module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MullenLowe U.S.","short_name":"MullenLowe U.S.","start_url":"/","background_color":"#f7f7f7","theme_color":"#232323","display":"standalone","icon":"src/images/svg/octopus-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"181c9ef8beeb9eaa942fbe28a9eb1463"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-150901869-3","anonymize":true},"linkedin":{"trackingId":"2797001","cookieName":"gatsby-gdpr-linkedin"},"facebookPixel":{"pixelId":"XXXXXXX","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
